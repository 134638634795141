import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { coreSlice, dashboardAttendanceSlice, dashboardPatientSlice } from '../../../../reducers'
import { useEvent } from '@emerald-works/react-event-bus-client'
import moment from 'moment'
import Loading from '../../../loading'
import timeDiff, { timeDiffSeconds } from '../../../../utils/timeDiff'
import { useMediaQuery } from '@mui/material'

const PatientRecords = () => {
  const user = useSelector(coreSlice.selectors.selectUser)
  const patient = useSelector(dashboardPatientSlice.selectors.selectPatient)
  const allAttendances = useSelector(dashboardAttendanceSlice.selectors.selectAllAttendances)
  const attendanceRecordings = useSelector(dashboardAttendanceSlice.selectors.selectAttendanceRecordings)
  const [getAllAttendances, getAttendanceRecordings, addAttendanceRecordingComment] = useEvent([dashboardAttendanceSlice.eventBus.getAllAttendances, dashboardAttendanceSlice.eventBus.getAttendanceRecordings, dashboardAttendanceSlice.eventBus.addAttendanceRecordingComment])
  const [selectedPeriod, setSelectedPeriod] = React.useState('')
  const [selectedAttendance, setSelectedAttendance] = React.useState()
  const [videoTimes, setVideoTimes] = React.useState([])
  const [videoComment, setVideoComment] = React.useState([])
  const dispatcher = useDispatch()
  React.useEffect(() => {
    dispatcher(dashboardAttendanceSlice.actions.setAttendanceRecordings([]))
  }, [selectedPeriod])

  React.useEffect(() => {
    if (patient?.pk) {
      getAllAttendances.trigger({ patient: patient?.pk, hasFinished: true })
    }
  }, [getAllAttendances, patient])

  const monthYears = React.useMemo(() => Array.from(new Set(allAttendances.map(({ start }) => `${moment(start).format('MMMM').toUpperCase()}/${moment(start).year()}`))), [allAttendances])
  const attendancesByPeriod = React.useMemo(() => {
    const attendancesByMonthYear = {}
    monthYears.forEach(monthYear => {
      attendancesByMonthYear[monthYear] = allAttendances.filter(({ start }) =>
        `${moment(start).format('MMMM').toUpperCase()}/${moment(start).year()}` === monthYear
      )
    })
    return attendancesByMonthYear
  }, [monthYears, allAttendances])

  React.useEffect(() => {
    if (patient && selectedAttendance) {
      getAttendanceRecordings.trigger({ patient: patient?.pk, start: parseInt(selectedAttendance) })
    }
  }, [selectedAttendance, patient])

  const isTabletOrMobile = useMediaQuery('(max-width:767px)')

  const videoRefs = React.useRef([])
  React.useEffect(() => {
    videoRefs.current = videoRefs.current.slice(0, attendanceRecordings.length)
    setVideoTimes(new Array(attendanceRecordings.length).fill(0))
    setVideoComment(new Array(attendanceRecordings.length).fill(''))
  }, [attendanceRecordings.length])

  const goToVideoMoment = React.useCallback((videoIndex, second) => {
    videoRefs.current[videoIndex].currentTime = second
  }, [videoRefs.current])

  const addComment = React.useCallback((i, slice) => {
    addAttendanceRecordingComment.trigger({
      start: selectedAttendance,
      patient: patient?.pk,
      comment: videoComment[i],
      moment: videoTimes[i],
      slice
    })
    setVideoComment(videoComment => {
      videoComment[i] = ''
      return Array.from(videoComment)
    })
  }, [selectedAttendance, patient, videoComment, videoTimes])

  const isAllowedToSeeComments = React.useMemo(() =>
    user.role === 'admin' || user.role === 'owner' || user.role === 'doctor', [
    user.role
  ])

  return (
    <section>
      <div className='grid flex-col w-full md:grid-cols-2 gap-2 my-2'>
        <div className='text-sm gap-1'>
          <label htmlFor='period' className='label'>Período</label>
          <select type='text' id='period' className='input flex content-center' value={selectedPeriod} onChange={({ target: { value } }) => setSelectedPeriod(value)}>
            <option value=''>Selecione um mês/ano</option>
            {monthYears.map(monthYear => <option value={monthYear} key={monthYear}>{monthYear}</option>)}
          </select>
        </div>

        <div>
          {selectedPeriod && attendancesByPeriod[selectedPeriod] &&
            <div className='text-sm gap-1'>
              <label htmlFor='record' className='label'>Atendimentos</label>
              <select type='text' id='record' className='input flex content-center' value={selectedAttendance} onChange={({ target: { value } }) => setSelectedAttendance(value)}>
                <option value=''>Selecione um atendimento</option>
                {selectedPeriod && attendancesByPeriod[selectedPeriod].map(({ start, end }) => (
                  <option
                    key={start}
                    value={start}
                  >
                    Início: {moment(start).format('DD/MM/YYYY HH:mm')} - Fim: {moment(end).format('DD/MM/YYYY HH:mm')}
                  </option>
                ))}
              </select>
            </div>}
        </div>
      </div>
      <div>
        <label>Gravações</label>
        {getAttendanceRecordings.isWorking ? <div className='flex justify-center'><Loading /> </div> : attendanceRecordings.length ?
          <div className='flex flex-wrap gap-4 justify-center'>
            {attendanceRecordings.map(({ url, comments, slice }, i) =>
              <div className={isTabletOrMobile ? 'flex flex-col shadow' : 'flex flex-row shadow'}>
                <div className='flex items-center'>
                  <video ref={v => videoRefs.current[i] = v} key={url} src={url} controls controlsList='nodownload' className={isTabletOrMobile ? 'h-full' : 'h-96 object-cover'} onTimeUpdate={() => {
                    setVideoTimes(videoTimes => {
                      videoTimes[i] = Math.floor(videoRefs.current[i].currentTime * 3600)
                      return Array.from(videoTimes)
                    })
                  }} />
                </div>
                {isAllowedToSeeComments && <div className='border-r px-2 max-h-96'>
                  <label className='label'>Comentários</label>
                  {!comments?.length && <p className='text-sm'>Não há comentários</p>}
                  <div className='h-52 overflow-auto'>
                    {comments?.map(comment =>
                      <div className='border-b'>
                        <div className='flex flex-1 gap-1'>
                          <p className='font-semibold text-sm'>{comment.commenter.name}</p>
                          <button className='text-sm cursor-pointer underline' onClick={() => { goToVideoMoment(i, timeDiffSeconds(comment.slice, comment.moment)) }}>{timeDiff(comment.slice, comment.moment)}</button>
                        </div>
                        <p className='text-sm'>{comment.comment}</p>
                      </div>
                    )}
                  </div>
                  <div className='grid'>
                    <p className='text-sm text-gray-700'>Momento: {timeDiff(videoTimes[i])}</p>
                    <textarea rows={1} className='input' onClick={() => {
                      videoRefs.current[i]?.pause()
                    }}
                      value={videoComment[i]}
                      onChange={({ target: { value } }) => {
                        setVideoComment(videoComment => {
                          videoComment[i] = value
                          return Array.from(videoComment)
                        })
                      }}
                    />
                    <button className='btn-primary' disabled={addAttendanceRecordingComment.isWorking} onClick={() => addComment(i, slice)}>Comentar</button>
                  </div>
                </div>}
              </div>
            )}
          </div> : selectedAttendance ?
            <div>Não há gravações para este atendimento</div> : <div>Selecione um atendimento</div>}

      </div>
    </section>
  )
}

export default PatientRecords
