import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { coreSlice, dashboardAttendanceSlice } from '../reducers'

export const PagesContext = React.createContext(null)

export const usePagesContext = () => {
  return React.useContext(PagesContext)
}

const DashBoardPages = {
  START: 0,
  PATIENTS: 1,
  AGENDA: 2,
  ATTENDANCE: 3,
  CHAT: 4
}

const SinglePatientPages = {
  PATIENT_DETAILS: 0,
  PATIENT_RECORDS: 1,
  PATIENT_REPORTS: 2,
  PATIENT_GRAPHICS: 3,
  PATIENT_ATTENDANCE: 4,
  PATIENT_MESSAGES: 5,
  PATIENT_AGENDA: 6
}

const AdminPages = {
  ADMIN_USERS: 0,
  ADMIN_PATIENTS: 1,
  ADMIN_DOCUMENTS: 2,
  ADMIN_SUBSCRIPTION: 3,
  ADMIN_ORG: 4
}

const AdminUserPages = {
  ADMIN_USER_LIST: 0,
  ADMIN_USER_CREATE: 1
}

const AdminPatientPages = {
  ADMIN_PATIENT_LIST: 0,
  ADMIN_PATIENT_CREATE: 1
}

const AdminSubscriptionPageModals = {
  PLAN: 'plan',
  PAYMENT: 'payment',
  HISTORY: 'history'
}

export const PagesContextProvider = ({ children }) => {
  const notActiveTenant = useSelector(coreSlice.selectors.selectNotActiveTenant)
  const dispatcher = useDispatch()
  const [dashBoardPage, setDashBoardPage] = React.useState(
    DashBoardPages.AGENDA
  )
  const [singlesPatientPage, setSinglePatientPage] = React.useState(
    SinglePatientPages.PATIENT_AGENDA
  )
  const [adminPage, setAdminPage] = React.useState(
    notActiveTenant ? AdminPages.ADMIN_SUBSCRIPTION : AdminPages.ADMIN_PATIENTS
  )
  const [adminUserPage, setUserPage] = React.useState(
    AdminUserPages.ADMIN_USER_LIST
  )
  const [adminPatientPage, setPatientPage] = React.useState(
    AdminPatientPages.ADMIN_PATIENT_LIST
  )
  const [dashBoardPageInfo, setDashBoardPageInfo] = React.useState({
    autoOpenNewEvent: false
  })

  const [adminSubscriptionPageModal, setAdminSubscriptionPageModal] =
    React.useState(null)

  const [showLeftBar, setShowLeftBar] = React.useState(false)

  const toggleShowLeftBar = () => setShowLeftBar(!showLeftBar)

  React.useEffect(() => {
    if (dashBoardPage !== DashBoardPages.PATIENTS) {
      setSinglePatientPage(SinglePatientPages.PATIENT_AGENDA)
      setUserPage(AdminUserPages.ADMIN_USER_LIST)
    }
  }, [dashBoardPage])

  React.useEffect(() => {
    if (singlesPatientPage !== SinglePatientPages.PATIENT_ATTENDANCE) {
      dispatcher(dashboardAttendanceSlice.actions.reset())
    }
  }, [dispatcher, singlesPatientPage])

  React.useEffect(() => {
    setAdminSubscriptionPageModal(null)
  }, [adminPage])

  return (
    <PagesContext.Provider
      value={{
        dashBoardPage,
        setDashBoardPage,
        singlesPatientPage,
        setSinglePatientPage,
        SinglePatientPages,
        DashBoardPages,
        dashBoardPageInfo,
        setDashBoardPageInfo,
        setAdminPage,
        adminPage,
        AdminPages,
        adminUserPage,
        setUserPage,
        AdminUserPages,
        showLeftBar,
        toggleShowLeftBar,
        setShowLeftBar,
        AdminPatientPages,
        adminPatientPage,
        setPatientPage,
        AdminSubscriptionPageModals,
        adminSubscriptionPageModal,
        setAdminSubscriptionPageModal
      }}
    >
      {children}
    </PagesContext.Provider>
  )
}
