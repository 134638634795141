import * as actions from './slice'
import { toast } from 'react-toastify'

export const getAgenda = {
  eventName: 'getAgenda',
  onSuccess: {
    action: actions.setAgenda,
    redux: true
  }
}

export const getMeetings = {
  eventName: 'getMeetings',
  onSuccess: {
    action: actions.setMeetings,
    redux: true
  }
}

export const createMeeting = {
  eventName: 'createMeeting'
}

export const updateMeeting = {
  eventName: 'updateMeeting'
}

export const deleteMeeting = {
  eventName: 'deleteMeeting'
}

export const getMeetingParticipants = {
  eventName: 'getMeetingParticipants',
  onSuccess: {
    action: actions.setMeetingParticipants,
    redux: true
  }
}

export const getPatientAgenda = {
  eventName: 'getPatientAgenda',
  onSuccess: {
    action: actions.setAgenda,
    redux: true
  }
}

export const removeInvite = {
  eventName: 'removeInvite',
  onSuccess: () => { toast.success('Convite removido com sucesso.') },
  onError: () => { toast.error('Falha ao remover convite.') }
}

export const sendInvite = {
  eventName: 'sendInvite',
  onSuccess: () => { toast.success('Convite enviado com sucesso.') },
  onError: () => { toast.error('Falha ao enviar convite.') }
}

export const addEventToAgenda = {
  eventName: 'addEventToAgenda',
  onSuccess: () => { toast.success('Evento adicionado com sucesso.') },
  onError: () => { toast.error('Falha ao criar o evento.') }
}

export const updateEventOnAgenda = {
  eventName: 'updateEventOnAgenda',
  onSuccess: () => { toast.success('Evento atualizado com sucesso.') },
  onError: () => { toast.error('Falha ao atualizar o evento.') }
}

export const removeEventFromAgenda = {
  eventName: 'removeEventFromAgenda',
  onSuccess: () => { toast.success('Evento removido com sucesso') },
  onError: () => { toast.error('Falha ao remover o evento.') }
}
