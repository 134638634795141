import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { componentsSelectDoctorSlice, doctorSlice } from '../../reducers'
import Select from 'react-select'

const SelectDoctor = ({ readOnly, modal, keepState }) => {
  const doctorsPerPage = 5
  const [search, setSearch] = React.useState('')
  const [searchBy, setSearchBy] = React.useState('name')
  const [isSearching, setIsSearching] = React.useState(false)
  const [searchDoctors] = useEvent([componentsSelectDoctorSlice.eventBus.searchDoctors])
  const doctorsFromSearch = useSelector(
    componentsSelectDoctorSlice.selectors.selectSearchDoctors
  )
  const doctor = useSelector(componentsSelectDoctorSlice.selectors.selectDoctor)
  const patientDoctors = useSelector(componentsSelectDoctorSlice.selectors.selectPatientDoctors)

  const dispatcher = useDispatch()

  React.useEffect(() => {
    if (!isSearching) {
      dispatcher(doctorSlice.actions.setSearchDoctors([]))
    }
  }, [dispatcher, isSearching])

  React.useEffect(() => {
    if (!keepState) {
      dispatcher(componentsSelectDoctorSlice.actions.setDoctor(null))
    }
  }, [dispatcher, keepState])

  const searchForDoctors = React.useCallback(() => {
    if (search.length >= 3) {
      setIsSearching(true)
      searchDoctors.trigger({ limit: doctorsPerPage, search, searchBy })
    }
  }, [search, searchBy, searchDoctors])

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(searchForDoctors, 2000)
    return () => clearTimeout(delayDebounceFn)
  }, [search, searchBy, searchForDoctors, searchDoctors])

  const filteredDoctorsFromSearch = doctorsFromSearch.filter(doctor => patientDoctors.find(({ pk }) => pk === doctor.pk) === undefined)

  const options = filteredDoctorsFromSearch.map(({ pk, name }) => ({
    label: `${name} - ${pk}`,
    id: pk
  }))

  const noOptionsMessage = () => (
    <>
      {searchDoctors.isWorking
        ? 'Buscando profissionais'
        : !filteredDoctorsFromSearch.length && isSearching
          ? 'Não foram encontrados profissionais'
          : `Digite o ${searchBy === 'email' ? 'e-mail' : 'nome'} do profissional`}
    </>
  )

  const setDoctor = ({ id }) => {
    const doctor = doctorsFromSearch.find(({ pk }) => pk === id)
    dispatcher(componentsSelectDoctorSlice.actions.setDoctor(doctor))
    setIsSearching(false)
  }

  return (
    <div className={`flex ${modal ? 'md:w-full' : 'md:w-[48rem]'} w-full flex-col md:flex-row gap-1`}>
      {!readOnly && (
        <div className='flex flex-col pb-2'>
          <label className='text-secondary-gray text-xs' htmlFor='searchBy'>
            Buscar usando
          </label>
          <label className='flex' id='searchBy'>
            <div
              onClick={() => setSearchBy('name')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'name' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Nome
            </div>
            <div
              onClick={() => setSearchBy('email')}
              className={`text-sm px-2 py-1 rounded-md cursor-pointer bg-opacity-10 ${searchBy === 'email' ? 'bg-secondary-gray' : 'bg-transparent'}`}
            >
              Email
            </div>
          </label>
        </div>
      )}
      <Select
        styles={{
          placeholder: (styles) => ({
            ...styles,
            color: readOnly ? styles.color : 'black'
          }),
          input: (styles) => ({
            ...styles,
            paddingTop: 10,
            paddingBottom: 10,
            color: 'black'
          }),
          control: (styles) => ({
            ...styles,
            backgroundColor: 'transparent'
          })
        }}
        isDisabled={readOnly}
        className='w-64 text-xs z-10'
        value={search}
        onChange={setDoctor}
        onInputChange={setSearch}
        noOptionsMessage={noOptionsMessage}
        options={options}
        placeholder={
          doctor?.pk
            ? `${doctor.name} - ${doctor.email}`
            : 'Selecione o profissional'
        }
      />
    </div>
  )
}
export default SelectDoctor
